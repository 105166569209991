.select-label {
  font-weight: bold;
  margin-top: 7px !important;
}

.first-column-card {
  padding-bottom: 10px !important;
  padding-right: 30px;
}

.ant-upload {
  width: 162px !important;
  height: 135px !important;
}

.ant-upload-select-picture-card > .ant-upload {
  width: 160px !important;
  height: 133px !important;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
