.pt-container {
    color: #0070ba;
    border-bottom: 0.7px solid #c9c9c9;
    margin-bottom: 12px;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 6px;
    padding-bottom: 8px;
    flex-wrap: wrap;
}

.pt-container > p {
    margin-bottom: 0px;
}

.pt-container > h1 {
    font-size: var(--font-size-breadcrumb);
    margin-bottom: 0px;
}

.pt-container > a {
    color: var(--off-level-color);
    font-size: var(--font-size-breadcrumb);
    margin-bottom: 0px;
}

.pt-container > a:hover {
    color: #0070ba;
}

.title-division {
    font-size: var(--font-size-breadcrumb);
    color: var(--off-level-color);
}

/* .title-detail {
    margin-bottom: 0px;
    font-size: var(--font-size-breadcrumb);
    color: #000;
} */