.main-container {
  padding: 1.25em 1em 1.5em;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  max-width: 1800px;
  // margin: auto !important;
}

.sweet-loading {
  display: block;
  text-align: center;
  padding: 20px;
}

.title-headers {
  color: #cb2431;
  margin-bottom: 20px !important;
}
.title-sections {
  color: rgb(255, 255, 255);
  display: inline-block;
  font-weight: 600;
  margin-top: unset;
  margin-bottom: 0px;
  font-size: 12px;
  background-color: rgb(6, 42, 94);
  text-align: center;
  border-radius: 3px;
  padding: 4px 8px;
}

.header-sub-container {
  display: flex;
}

.header-menu-item {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  vertical-align: baseline;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 1rem;
  padding-top: 9px;
  font-weight: 400;
  height: 44px;
  display: flex;

  .icon-div {
    margin-right: 5px;
  }

  .icon-div-icon {
    margin-right: 2px;
    margin-left: 2px;
  }
}

.header-menu-item:hover {
  cursor: pointer;
  background-color: #1b1b1b;
  border-bottom: 2px solid rgb(255, 255, 255);
}

.header-menu-item-icon {
  text-align: center;
  vertical-align: baseline;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 1.1rem;
  padding-top: 9px;
  height: 44px;
  display: flex;
}

.header-menu-item-icon:hover {
  cursor: pointer;
  background-color: #1b1b1b;
}

.link-a-menu {
  text-decoration: none;
  text-transform: none;
  color: white;
  background-color: red;
  /* padding: 15px; */
  height: 62px;
  /* font-size: 19px; */
  padding-left: 15px;
  padding-right: 15px;
  /* padding-top: 51px; */
  /* padding-bottom: 10px; */
  margin-top: 43px;
}

.user-name-logged {
  background-color: #363131;
  border-radius: 7px;
  height: 27px;
  margin-top: 9px !important;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
}
