.badge {
  background-color: var(--primary);
  padding: 0px 6px;
  border-radius: 4px;
  color: white;
}

.container-between {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.pagination-component-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}

.hidden {
  display: none;
}

.truncate {
  overflow: hidden; /* Oculta el desbordamiento */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
  text-overflow: ellipsis; /* Muestra puntos suspensivos al final */
}

.truncated-text {
  width: 100%; /* Ancho máximo del contenedor */
  white-space: nowrap; /* Impide que el texto se divida en varias líneas */
  overflow: hidden; /* Oculta el texto que excede el ancho */
  text-overflow: ellipsis; /* Muestra puntos suspensivos al final del texto */
}
