.nav-link-detail {
  color: #cccccc;
}

.nav-breadcrumb-container {
  .nav-path {
    display: flex;
    gap: 8px;
  }

  a {
    color: black;
  }

  p {
    margin-bottom: 0px;
  }
}

.search-container {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
}

.sc-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.partner-card-container {
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--border-primary);
  background-color: #fff;

  .info {
    padding: 6px 14px;
    flex: 1;
    overflow: hidden;

    p {
      margin-bottom: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
    }
  }
}

.partner-card-container:hover {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.personal-picture {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.grid-personal {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 640px) {
  .grid-personal {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid-personal {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .grid-personal {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.cr-container {
  display: flex;
  flex-direction: column;
  // gap: 10px;
}

.compliance-company {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.3s ease;
  background-color: #fff;

  p {
    margin-bottom: 0px;
    font-weight: 600;
  }
}

.BCEBVd {
  border: 1px solid var(--border-secondary);
}

.BCEBVd:not(:first-child) {
  border-top: 0px;
}

.BCEBVd:first-child {
  border-radius: 8px 8px 0 0;
}

.BCEBVd:last-child {
  border-radius: 0 0 8px 8px;
}

.cc-only-hover:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.cc-only-hover:first-child {
  border-radius: 8px 8px 0 0;
}

.cc-only-hover:last-child {
  border-radius: 0 0 8px 8px;
}

.cc-expanded:hover {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  background-color: #e7e9eb;
}

.S90skc {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  align-items: center;
  display: flex;
  flex: 1 1 0;
  height: 40px;
  min-width: 0;
  white-space: nowrap;
}

// .aux-border {
//   border-radius: 6px;
//   border: 1px solid var(--border-secondary);
// }

// .aux-background {
//   background-color: #efefef;
// }

.rc-name {
  padding: 16px 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.ar-container {
  padding: 16px 38px;
  display: flex;
  flex-direction: column;
}

.ar-table {
  display: flex;
  gap: 20px;
  padding-bottom: 38px;
  margin-bottom: 38px;
  background-color: #fff;
  border-bottom: 1px solid rgb(211, 211, 211);
  p {
    font-weight: 400;
  }
}

.ar-table:last-child {
  border-bottom: none;
  padding-bottom: 0;
  // margin-bottom: 0;
}

.ar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  h3 {
    font-size: 18px;
    margin-bottom: 0px;
  }
}

// .company-image {
//   width: 40px;
//   height: 40px;
//   object-fit: contain;
//   border-radius: 100%;
//   border: 1px solid #e7e7e7;
//   padding: 2px;
// }

.zBTtmb {
  margin: 16px 0 6px 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
}

.yCTReb {
  line-height: 18px;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: var(--gray-02);
}

.xvIywb {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.T6Yut {
  border: 2px solid #dadce0;
  border-radius: 12px;
  box-sizing: border-box;
  height: 12px;
  width: 12px;
}
.Vd4i6d {
  border-right: 4px dotted #dadce0;
  flex: 1;
  margin-bottom: 4px;
  margin-top: 4px;
  width: 4px;
}
