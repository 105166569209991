@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.vk-text-center {
  text-align: center;
}

.vk-text-right {
  text-align: right;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.login-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.button-full-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
}

.login-button {
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  background-color: #cc0000 !important;
  text-transform: none !important;
}

.login-button:hover {
  background-color: #a70303 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) !important;
}

.card-layout {
  border-radius: 4px;
  box-shadow: 0 15px 35px 0 rgba(60, 66, 87, .08), 0 5px 15px 0 rgba(0, 0, 0, .12);
  background: #fff;
  width: 100%;
  max-width: 420px;
  padding: 46px 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-layout > img {
  max-width: 300px;
  margin-bottom: 50px;
}

.card-layout > h3 {
  margin-bottom: 10px;
}

.card-layout > form {
  width: 100%;
}

.bold-label {
  font-weight: 600 !important;
}

.search-form-container {
  background: #fff;
  padding: 18px 25px;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .15);
}

.search-filters {
  margin-bottom: 36px;
}

.search-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.result-form-container {
  background: #ffffff;
  margin-top: 20px;
}

.button-link {
  border: 1px solid;
  border-radius: 6px;
  appearance: none;
  color: #24292f;
  background-color: #f6f8fa;
  border-color: #b2b3b3;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  padding: 2px 10px;
  font-weight: 500;
  cursor: pointer;
}

.button-link:hover {
  background-color: #ededee;
}

.title--option-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fw-500 {
  font-weight: 500 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.public-assess-card-container {
  background-color: white !important;
  min-width: 400px;
  max-width: 600px !important;
  padding: 15px;
}

.public-assess-card {
  background-color: #ededed;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  margin-bottom: 15px;
}

.assess-number {
  color: #464646;
  font-size: 12px;
}

.btn {
  position: relative;
  display: inline-block;
  padding: 2px 10px;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  border-radius: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #24292f;
  background-color: #f6f8fa;
  border-color: #b2b3b3;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  font-weight: 500;
}

.color-green {
  background-color: #2da44e;
  border-color: #2da44e;
  color: white;
}

.btn:hover {
  background-color: #dbdbdb;
}

.color-green:hover {
  background-color: #2b8845;
  color: white;
}

.color-blue {
  background-color: var(--primary);
  border-color: var(--primary);
  color: white;
}

.color-blue:hover {
  background-color: var(--primary-hover);
  color: white;
}

.btn:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.file-upload {
  margin: 0px;
  margin-top: 4px;
  margin-left: 10px;
}

.btn-link {
  background: none;
  border: none;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  box-shadow: none;
}

.btn-link:hover {
  color: var(--primary-hover);
  text-decoration: none;
  background: none;
}

.btn-link:focus {
  outline: none;
  text-decoration: none;
}

.border-popup-header {
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #e8e8e8;
}

.start-assessment-info {
  max-width: 710px;
  width: 100%;
  margin: auto;
  padding: 1.25em;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 15px;
}

.applicant-info {
  background-color: #dfeefd;
  border: 1px solid #1d76d2;
  color: #0548ad;
  border-radius: 3px;
  padding: 8px;
  font-size: 14px;
}

.applicant-profile-image {
  width: 100px;
}

.profile-image {
  width: auto;
  height: 100%;
  max-height: 150px;
  max-width: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.profile-image-alter {
  width: auto;
  height: 100%;
  max-height: 150px;
  max-width: 150px;
  border-radius: 5px;
  object-fit: cover;
}

.applicant-contractor-row {
  background-color: #e1e1e1;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 15px !important;
  border: 0.4px solid #d7d7d7;
}

.upload-photo-button {
  width: 120px;
  margin-left: -14px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
}

.upload-photo-button:hover {
  cursor: pointer;
  border: 1px solid #7e7e7e;
}

.center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-profile-photo {
  width: 120px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
}

.status_empire {
  border-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
}

.not-valid,
._red {
  background-color: #fddede;
  color: #c62828;
}

.valid,
._green {
  background-color: #d0f0c0;
  color: #2e7d32;
}

.badge-new {
  background-color: #e8eaf6;
  color: #3f51b5;
}

.badge-info {
  background-color: #e3f2fd;
  color: #0d47a1;
}

._purple {
  background-color: #5319e7;
  color: #fff;
}

.no-required,
._disabled {
  background-color: #e2e2e2;
  color: #333;
}

._orange {
  background-color: #f49118;
  color: #fff;
}

.badge-warning {
  background-color: #f8e6c1;
  color: #593c12;
}

._diagnostic {
  background-color: #858585;
  color: #fff;
}

._second_blue {
  background-color: #e3f2fd;
  color: #0d47a1;
}

._second_red {
  background-color: rgba(220, 105, 101, 0.5) !important;
  color: #333;
}

.badge-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-status {
  border-radius: 4px;
  padding: 1px 6px;
  min-height: 20px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;

  &._archived,
  &.draft {
    background-color: #ebeef1;
  }

  &._approved,
  &._valid,
  &._D,
  &._confirmed {
    background-color: #d7f7c2;
    color: #006944;
  }

  &._completed {
    background-color: #cff5f6 !important;
    color: rgb(0, 85, 188);
  }

  &._no_show,
  &._expired,
  &._A,
  &._rescheduled {
    background-color: #f6cfcf !important;
    color: rgb(175 49 49);
  }

  &._rejected,
  &._cancelled {
    background-color: #f6cfcf !important;
    color: rgb(175 49 49);
  }

  &._draft,
  &._pending,
  &._revoked,
  &._pending_confirmation {
    background-color: #ffe9b0 !important;
    color: rgb(0 0 0);
  }

  &._on_hold,
  &._in_progress {
    background-color: #a4e6ff;
    color: #174066;
  }

  &._B {
    background-color: #ffe9b0 !important;
    color: #000000;
  }

  &._C {
    background-color: #fff700;
    color: #000000;
  }
}

.requirement-container {
  padding: 10px 10px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 3px;
  display: flex;
  gap: 10px;
  align-items: center;
  min-height: 45px;
}

.vk-container-vertical {
  float: left;
  width: 235px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.req-detail {
  /* display: flex; */
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.requirement-container:last-child {
  border-bottom: none;
}

.requirement-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-group {
  text-align: right;
}

.rf-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: 25px;
}

.company-logo-request-form {
  text-align: center;
  margin-bottom: 25px;
}

.rf-logo-company {
  max-width: 240px;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.aux-container {
  display: flex;
  margin-top: 30px;
}

.rf-rq-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .radio-group {
    text-align: left;
  }
}

.loading-container {
  width: 100%;
  text-align: center;
  padding: 80px 0px;
}

/* .section-title-h2 {
  margin-top: 0px;
  color: #0070ba;
  font-weight: 400;
} */
.breadcrumb-bar {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e5e8ed;
  padding: 5px;
  margin-left: -0.6%;
  margin-top: -0.6%;
}

.space-bar {
  font-weight: normal;
  padding: 5px;
  text-decoration: none;
  font-size: 1.2rem
}

.excel-preview-container {
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  cursor: zoom-in;
}

.mp4-preview-container {
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  cursor: zoom-in;
}

.pdf-preview-container {
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  cursor: zoom-in;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.appointment-name {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 8px !important;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
}

.appointment-name h1 {
  margin-bottom: 0px !important;
}

.appointment-date {
  padding: 6px;
  background: #e3e3e3;
  border-radius: 4px;
  margin-bottom: 0px;
}

.vk-mb-0 {
  margin-bottom: 0px;
}

.dsh-section-title {
  margin-bottom: 0px;
  font-weight: 600;
}

.adjunto-btn {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2px 10px;
  cursor: pointer;
  border: 1px solid #c9c9c9;
  color: #000000;
}

.req-attachment-container {
  display: flex;
}

.req-attachment-name {
  padding: 8px;
  background-color: #ededed;
  border-radius: 6px;
  margin-bottom: 8px !important;
}

.req-attachment-name:hover {
  background-color: #dcdcdc;
  cursor: pointer;
}

.req-attachment-name:last-child {
  margin-bottom: 0px;
}

.merge-contact-row {
  background-color: #f5f5f5;
  padding: 10px;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  margin-bottom: 3px;
  border-radius: 6px;
  border: 1px solid #dbdbdb;
}

.merge-contact-row:last-child {
  margin-bottom: 0px;
}

.download-link {
  text-decoration: none;
  background: transparent;
  border: none;
  color: var(--primary);
}

.download-link:hover {
  text-decoration: underline;
  color: #115293;
  cursor: pointer;
}

.setting-matrix-layout {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
  border-bottom: 1px solid #e4e7eb;
}

.setting-matrix-layout a {
  color: #32325d;
  text-decoration: none;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
  border-bottom: 1px solid transparent;
  position: relative;
  margin-bottom: -1px;
  padding-bottom: 6px;
}

.setting-matrix-layout a:hover {
  color: #0070ba;
}

.setting-matrix-layout .active-link {
  color: #0070ba;
  border-bottom-color: #0070ba;
  font-weight: 600;
  margin-bottom: -1px;
}

.company-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.company-row {
  padding: 10px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.company-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.company-container:last-child {
  margin-bottom: 0px;
}

.matrix-container {
  padding: 10px;
  background-color: #8bc4e736;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
}

.ar-row-category {
  background-color: #e2e2e2;
  padding: 10px;
  /* border-radius: 6px; */
  font-weight: 600;
  border: 1px solid #d7d7d7;
  border-bottom: none;
}

.ar-row-value {
  background-color: #ffffff;
  padding: 10px;
  /* border-radius: 6px; */
  font-weight: 600;
  border: 1px solid #d7d7d7;
  border-bottom: none;
}

.ar-row-value:last-child {
  border-bottom: 1px solid #d7d7d7;
}

.ar-row-value:hover {
  background-color: #f5f5f5;
}

.container-add-user {
  background-color: #fbf8f8;
  padding: 10px;
  border: 1px solid #e3e3e3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.container-users-list {
  margin-top: 20px !important;
  padding: 20px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e3e3e3;
  -moz-border-radius: 4px;
}

.container-docs-no-margin {
  padding: 20px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e3e3e3;
  -moz-border-radius: 4px;
}

.container-option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
}

.company-option-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  flex-wrap: wrap-reverse;
  gap: 4px
}

.client-option-container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.identity-result-container {
  margin: 10px
}

.assign-assessment-options {
  display: flex;
  align-items: center;
}

.comment-container {
  background-color: #f3f3f3;
  border-radius: 10px;
  margin-bottom: 15px !important;
  padding: 15px !important;
  border: 1px solid #d9d9d9;
}

.comment-container p {
  margin: 0;
  line-height: 2;
}

.comment-text {
  padding: 2px;
  margin-left: 45px;
}

.comment-user-name {
  font-weight: bold;
  margin-left: 10px;
}

.comment-created {
  color: #adb5bd;
  font-size: 0.9em;
  font-weight: 500;
}

.modules-navbar {
  display: flex;
}

.menu-text {
  font-size: 14px !important;
}

.assessment-mobile-card {
  text-decoration: none;
  display: block;
  color: black;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 18px;
  background-color: white;
  height: 100%;
  border: 1px solid var(--primary);
  transition: border-color .5s ease-in-out;
  box-shadow: 0 2px 5px #32325d1a, 0 1px 1.5px #00000012;
}

.assessment-mobile-card:last-child {
  margin-bottom: 0px;
}

.word-preview-container {
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  cursor: zoom-in;
}

.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.negative-message-container {
  position: relative;
  min-height: 1em;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  background-color: #fff6f6;
  color: #9f3a38;
  font-size: 1em;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  border-radius: 0.28571429rem;
}

.negative-title {
  font-weight: 600;
  color: #912d2b;
  font-size: 16px;
}

.cell-option {
  display: flex;
  gap: 6px;
  margin-bottom: 10px;
}

.option-container {
  display: flex;
  gap: 6px;
  flex-wrap: nowrap;
}

.center-flex {
  justify-content: center;
}

.aux-detail:hover {
  background-color: rgb(214, 214, 214);
  cursor: pointer;
  border-radius: 4px;
}

.personal-in-doc {
  background-color: #fff;
  padding: 24px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  margin-bottom: 11px !important;
}

.compliance-doc-row:hover {
  background-color: #f5f5f5;
}

/* Extra Small Devices, Phones */
@media (max-width: 480px) {
  :root {
    --font-size-breadcrumb: 14px;
  }

  .main-container {
    margin-left: 5px;
    margin-right: 5px;
    /* background-color: green !important; */
  }

  .modules-navbar {
    display: none;
  }

  #year-of-invitation {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  #status-of-invitation {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  #select-records {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
}

/* Small Devices, Large Phones */
@media (min-width: 481px) and (max-width: 768px) {
  :root {
    --font-size-breadcrumb: 14px;
  }

  .card-layout {
    padding: 32px 18px;
    margin: 0 16px;
  }

  .main-container {
    margin-left: 5px;
    margin-right: 5px;
    /* background-color: red !important; */
  }

  .modules-navbar {
    display: none;
  }

  #year-of-invitation {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  #status-of-invitation {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  #select-records {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .assess-title {
    font-size: 14px;
  }
}

/* Medium Devices, Tablets */
@media (min-width: 769px) and (max-width: 1024px) {
  .main-container {
    margin-left: 10px;
    margin-right: 10px;
    /* background-color: blue !important; */
  }

  .modules-navbar {
    display: none;
  }

  .assign-assessment-options {
    padding-left: 10px;
  }
}

/* Large Devices, Laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  .main-container {
    margin-left: 10px;
    margin-right: 10px;
    /* background-color: orange !important; */
  }

  .modules-navbar {
    display: none;
  }

  .assign-assessment-options {
    padding-left: 10px;
  }
}

@media (min-width: 1200px) and (max-width: 1250px) {
  .menu-text {
    font-size: 13px !important;
  }
}

/* Extra Large Devices, Wide Screens */
@media (min-width: 1201px) {
  .main-container {
    margin-left: 20px;
    margin-right: 20px;
    /* background-color: purple !important; */
  }

  .assign-assessment-options {
    padding-left: 10px;
  }
}

/* Extra Large Screens (TVs, High-Resolution Monitors - over 1440px or 1920px) */
@media (min-width: 1440px) {
  .main-container {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: 1800px) {
  .main-container {
    margin-left: auto;
    margin-right: auto;
  }
}

.alter-container-div {
  background-color: #fff;
  margin: 20px auto;
  max-width: 800px;
  width: 100%;
  padding: 18px;
  border-radius: 8px;
}

.play-pause-button {
  background-color: #f0f0f5;
  /* Fondo suave tipo Apple */
  border: 1px solid #d1d1d6;
  /* Borde suave */
  border-radius: 12px;
  /* Bordes redondeados suaves */
  padding: 10px 20px;
  /* Espaciado interno */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  font-size: 16px;
}

.play-pause-button:hover {
  background-color: #e0e0e5;
  /* Cambio suave en hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  /* Sombra más intensa */
}

.play-pause-button:active {
  background-color: #d0d0d5;
  /* Efecto click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Sombra más ligera en click */
  transform: scale(0.98);
  /* Efecto de clic */
}

.play-pause-button svg {
  font-size: 24px;
  /* Tamaño del ícono */
}

.styled-range {
  -webkit-appearance: none;
  /* Elimina el estilo predeterminado */
  width: 100%;
  /* Anchura del rango */
  height: 8px;
  /* Altura de la barra */
  border-radius: 5px;
  /* Bordes redondeados */
  outline: none;
  /* Elimina el borde al hacer clic */
  opacity: 0.8;
  /* Transparencia ligera */
  transition: opacity 0.3s, background 0.3s ease;
  /* Transición suave */
}

.styled-range:hover {
  opacity: 1;
  /* Aumenta la opacidad al pasar el ratón */
}

.styled-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Elimina el estilo predeterminado del thumb */
  appearance: none;
  width: 20px;
  /* Ancho del thumb */
  height: 20px;
  /* Alto del thumb */
  border-radius: 50%;
  /* Thumb circular */
  background: #007aff;
  /* Color azul estilo Apple */
  cursor: pointer;
  /* Cursor de puntero al pasar por el thumb */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Sombra ligera en el thumb */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  /* Transiciones suaves */
}

.styled-range::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  /* Escala el thumb en hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Sombra más intensa en hover */
}

.styled-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #007aff;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.styled-range::-moz-range-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.question-container {
  background-color: #fff;
  /* Fondo blanco para destacar */
  border: 1px solid #e0e0e0;
  /* Borde sutil */
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Sombra ligera */
}

.question-text {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.assessment-title {
  font-size: 18px;
  color: #444;
  margin-bottom: 24px;
}

.answer-option {
  margin-bottom: 8px;
}

.answer-option:last-child {
  margin-bottom: auto;
}

.label-caption {
  font-size: 0.875rem;
  color: #6c757d;
  margin-bottom: 0;
  width: 130px
}

.label-value {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0px;
}

/* Tailwind */
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.p-2 {
  padding: 0.5rem;
}

.bg-muted {
  background-color: #f5f5f5;
}

.rounded-md {
  border-radius: 0.375rem;
}

.space-y-2 {
  > * + * {
    margin-top: 0.5rem;
  }
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.h-4 {
  height: 1rem;
}

.w-4 {
  width: 1rem;
}

ul {
  list-style-position: inside;
  padding-left: 0;
}

.status-guard {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.4rem 0.4rem;
  border-radius: 5px;
}

.success-guard {
  background-color: #d0f0c0;
  color: #2e7d32;
  border: 1px solid #c3e6cb;
}

.error-guard {
  background-color: #e53e3e;
  color: #fff;
  border: 1px solid #f5c6cb;
}