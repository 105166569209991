.label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  border: 0.1rem dashed #c5ced7;
  gap: 12px;
  padding: 18px 0;
}

.remove-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 2.7px;
  border-radius: 0.2rem;
  cursor: pointer;
}

.remove-button:hover {
  background-color: #d1d1d1;
}

.upload-file-container {
  background-color: #f0f3f5;
  padding: 1rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-card-info {
  display: flex;
  flex-direction: column;

  #name {
    font-weight: 500;
  }
}

.border-primary {
  border: 2px solid #1d4ed8; /* Cambia este color a tu color primario deseado */
}
