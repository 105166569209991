.asst-card {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid var(--border-primary);
}

.asst-card:last-child {
  margin-bottom: 0px;
}

.company-name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.contractor-name {
  font-size: 16px;
  color: var(--gray-02);
  margin-bottom: 0px;
}

.secondary-description {
  font-size: 14px;
  color: var(--gray-03);
  margin-bottom: 0px;
}

.secondary-values {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.row-container {
  background-color: #ffffff;
  padding: 16px 22px;
  border: 1px solid var(--border-primary);
  border-radius: 12px;
  margin-bottom: 16px;
}

.row-container:last-child {
  margin-bottom: 0px;
}

.assess-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}

// .compliance-status {
//   padding: 0.2rem 0.5rem;
//   border-radius: 0.25rem;
//   display: inline-block;
// }

.compliant {
  background-color: #4caf50; /* Verde */
  color: white;
}

.pending {
  background-color: #ffeb3b; /* Amarillo */
  color: black;
}

.no-required {
  background-color: #9e9e9e; /* Gris */
  color: white;
}
