.gallery-image:after,
.gallery-image:before {
  position: absolute;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.gallery-image:after {
  content: '\A';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.button-image {
  position: absolute;
  left: 40%;
  top: 50%;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  text-align: center;
  color: #262626;
  z-index: 1;
}

.gallery-image:hover:after,
.gallery-image:hover:before,
.gallery-image:hover .button-image {
  opacity: 1;
}
