.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  /* border: 1px solid silver; */
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.wrap li {
  /* background: gold; */
}

.wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
.wrap-reverse li {
  background: deepskyblue;
}

.flex-item {
  padding: 5px;
  width: 350px;
  height: 140px;
  margin: 10px;
  /* 
  line-height: 100px;
  color: white;
  font-weight: bold; */
  /* font-size: 2em; */
  /* text-align: center; */
}

.selected-client {
  background: #cccccc;
  border-radius: 3px 0px 0px 3px;
  padding: 0px 10px 0px 10px;
  border-radius: 3px;
  color: #000;
  display: inline-block;
  height: 34px;
  line-height: 33px;
  /* padding: 0 20px 0 23px; */
  position: relative;
  margin: 0 10px 10px 0;
  /* text-decoration: none; */
  /* -webkit-transition: color 0.2s; */
  font-size: 20px;
}
