.tab-option-container {
  display: flex;
  gap: 20px;
}

.bottom-border {
  border-color: var(--border-primary);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.tab-item {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: transparent;
  cursor: pointer;

  span {
    margin-top: 4px;
    margin-bottom: 8px;
    display: block;
    border-radius: 8px;
    color: #000;
  }
}

.tab-item:hover {
  span {
    color: var(--primary);
  }
}

.tab-item-selected {
  border-color: var(--primary);

  span {
    font-weight: 600;
    color: var(--primary);
  }
}
