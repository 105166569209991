.file-card {
  border-radius: 6px;
  background: #eaeaeaf5;
  padding: 10px;
  /* width: 264px; */
  height: 70px;
  margin: 10px;
}

.file-card:hover {
  border: 0.4px solid rgb(190, 189, 189);
}

.file-names {
  width: 190px;
  word-break: break-all;
  white-space: normal;
}
