.datePickerWrapper {
    width: 100%;
}

.datePicker {
    width: 100%;
    height: 38px;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding-left: 5px;
}

.datePicker:hover {
    border-color: #9c9c9c;
}
